
import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import "magnific-popup";
import { StickyElement } from "static/js/app/modules/stickyElement";
import VehicleSingleTemplate from "static/js/app/modules/vehicleSingleTemplate";
import * as Api from "static/js/app/api/endpoints";
import VehiclePageMeta from "static/js/app/modules/vehiclePageMeta";
import VehicleGallery from "static/js/app/modules/vehicleGallery";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";

import { HugoVehicleDetailsPageConfig } from "static/js/app/hugoSettings/hugoSiteConfig";
import * as Models from "static/js/app/models/__index";
import { VehiclePageConfig } from "static/js/app/hugoSettings/pageConfigs/vehiclePageConfig";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import GoogleAnalytics from "themes/module_google_analytics/static/js/googleAnalytics";
import VehicleRunningCostsCalculator from "themes/module_running_costs_calculator/static/js/vehicleRunningCostsCalculator";
import VehicleBranchDetails from "themes/module_vehicle_branch_details/static/js/vehicleBranchDetails";
import { finance_plugin } from "themes/module_finance_plugin/static/js/finance_plugin";
import { PartExchange } from "themes/module_part_exchange/static/js/partExchange";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";
import SimilarVehicles from "themes/module_similar_vehicles_slider/static/js/similarVehicles";
import { ModuleSimilarVehiclesConfig } from "themes/module_similar_vehicles_slider/static/js/similarVehiclesConfig";
export default class NewVehiclePage {
	public static init(siteConfig: SiteConfig, onTemplatesLoaded: () => void) {
        Page.ready(function () {
            VehicleGallery.init();
        });

    }
}