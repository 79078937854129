export default class StringFormatting {
    public static numberToString(number: Number, valueWhenZero: string = "0", valueWhenNull: string = "") {
        return (number === undefined || number === null) 
            ? valueWhenNull
            : (number === 0) 
                ? valueWhenZero
                : number.toString();
    }

    public static numberWithCommas(x: number, valueWhenZero: string = "0", valueWhenNull: string = "") : string {
		return StringFormatting.numberToString(x, valueWhenZero, valueWhenNull).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    public static stringInBrackets(x: string,valueWhenNull: string = ""): string{
        return (x === null || x ===undefined) ? valueWhenNull 
        :  `(${x})`;
    }

    public static titleCaseString(x: string, valueWhenNull: string = ""): string{
        return (x === null || x ===undefined) ? valueWhenNull :
        x.toLowerCase().split(' ').map(str => {
            return (str.charAt(0).toUpperCase() + str.slice(1));
          }).join(' ');
    }

    public static coerceNullishToBlank = (obj: Object, output?: string) => (obj === undefined || obj === null) ? "" : output != null ? output : obj.toString();

    public static dateToString(rawDate: Date, locale:string="en-GB", valueWhenNull: string=""): string {
        
        const dateFormatOptions:Intl.DateTimeFormatOptions = { day: "numeric", month: "numeric", year: "numeric" };
        
        const dateFormat = new Intl.DateTimeFormat(locale, dateFormatOptions);

        return (rawDate === undefined || rawDate === null) 
            ? valueWhenNull
            :  dateFormat.format(new Date(rawDate.toString()));
               
    }
    //Returns a  YYYY-MM-DD string for a given date - this format is needed for HTML5 date control
    public static dateAsFormattedString(rawDate: Date, valueWhenNull: string=""): string {
                
        if (rawDate === undefined || rawDate === null) 
        {
            return valueWhenNull;
        }
        let actualDate =  new Date(rawDate.toString())
        let monthValue = (actualDate.getMonth()+ 1 < 10 ? `0${actualDate.getMonth()+ 1}` : `${actualDate.getMonth()+ 1}`);
        let dayValue = (actualDate.getDate() < 10 ? `0${actualDate.getDate()}`: `${actualDate.getDate()}`);
        return `${actualDate.getFullYear()}-${monthValue}-${dayValue}`;                       
    }
}
