import * as Models from "static/js/app/models/__index";
import { codeweavers_finance_calculator } from "themes/module_finance_plugin/static/js/codeweavers_finance_plugin";
import {ivendi_finance_calculator} from "themes/module_finance_plugin/static/js/ivendi_finance_plugin";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";
import { apiBaseUrl } from "static/js/app/api/snSvrApi";
import * as Api from "static/js/app/api/endpoints";
import finance_checker from "themes/module_finance_plugin/static/js/vehice_finance_checker";
import { evolution_finance_calculator } from "themes/module_finance_plugin/static/js/evolution_finance_plugin";
export class finance_plugin
{
    public static init( financeConfig: FinanceConfig, financeTerms: Models.FinanceSearchTerms, vehicleSingle: Models.Vehicle)
    {       
        if (financeTerms != null && vehicleSingle !=null)
        {
            switch(financeConfig.provider) {
                case "codeweavers":
                    
                    codeweavers_finance_calculator.init(financeTerms,vehicleSingle,financeConfig.finance_cta);
                   
                    break;
                case "ivendi":
                    if (vehicleSingle.sold == false)
                    {
                        finance_plugin.fetchMonthlyPrice(vehicleSingle.id, financeTerms);
                    }
                  
                    ivendi_finance_calculator.init(financeConfig.api_key_username,financeTerms,vehicleSingle);
                    if (financeConfig.reserve_button_type =="ivendi")
                    {
                        ivendi_finance_calculator.initReserveButton(financeConfig.api_key_username,financeTerms,vehicleSingle);
                    }
                    if (financeConfig.finance_checker == "both" || financeConfig.finance_checker == "vehicle details" )
                    {
                        ivendi_finance_calculator.initFinanceChecker(financeConfig.api_key_username,financeTerms,vehicleSingle);
                    }
                    break;
                case "evolution":
                    if (vehicleSingle.sold == false)
                    {
                        evolution_finance_calculator.init(financeConfig.api_key_username,financeTerms,vehicleSingle);
                        finance_plugin.fetchMonthlyPrice(vehicleSingle.id, financeTerms);
                    }
                    break;
                default: 
                    break;
            }
        }
    }

    public static fetchMonthlyPrice( vehicleId: string, financeTerms:Models.FinanceSearchTerms)
    {
        const monthlyPayementLink = document.getElementById("monthlyPaymentLink");
        if (monthlyPayementLink !=null) {
              
            
            Api.Vehicles.getMonthlyPrice(vehicleId, financeTerms).then(function(monlthyPrice: number)
            {
                finance_plugin.setMonthlyPrice(monlthyPrice, monthlyPayementLink);
                    
            }).catch(function() {

                finance_plugin.hideMonthlyPrice(monthlyPayementLink);
            }
       );
        }
    } 

    public static fetchMonthlyPrices( monthlyPriceElements: HTMLElement[], financeTerms:Models.FinanceSearchTerms, financeCheckerLinks: HTMLElement[] | null)
    {
       
        if (monthlyPriceElements !=null && monthlyPriceElements.length > 0) {
             
            if (financeTerms.isDefaultTerms == true)
            {
                monthlyPriceElements.forEach(e=> { e.classList.remove("invisible");});
            }
            else{          
            const pricesRequest = new Models.MonthlyPricesRequest(monthlyPriceElements.map(m=> m.dataset.vehid), financeTerms.percentage_deposit, financeTerms.deposit_amount, financeTerms.term, financeTerms.annual_mileage);
            Api.Vehicles.fetchMonthlyPrices(pricesRequest).then(f=> 
            {
                monthlyPriceElements.forEach(mp=> {
                   let priceMatch = f.find(f=> f.car_id == mp.dataset.vehid );                  
                    
                   if (priceMatch != null)
                   {                    
                        finance_plugin.setMonthlyPrice(priceMatch.monthly_price, mp );
                    
                   }
                   else{
                    finance_plugin.hideMonthlyPrice(mp);
                   }
                });
            if (financeCheckerLinks != null && financeCheckerLinks.length > 0)
            {
                financeCheckerLinks.forEach(fc=> {
                    let priceMatch = f.find(f=> f.car_id == fc.dataset.vehid );                  
                     
                    if (priceMatch != null && priceMatch.quote_id !=null)
                    {                    
                         finance_checker.SetFinanceCheckerUrl(fc, priceMatch.quote_id);
                     
                    }
                    else{
                        finance_checker.HideFinanceCheckerCTA(fc);
                    }
                 });
            }
                    
            }).catch(function() {
                monthlyPriceElements.forEach(mp=> finance_plugin.hideMonthlyPrice(mp));                
            });                   
        }
    }
    } 

 
    
    public static setMonthlyPrice(monthlyPrice: number, monthlyPaymentLink: HTMLElement) 
    {        
        if (monthlyPaymentLink && monthlyPrice)
        {
            monthlyPaymentLink.classList.remove("invisible");
            const monthlyPriceElement = monthlyPaymentLink.querySelector<HTMLElement>(".monthlyPaymentAmount");
            if (monthlyPriceElement !=null)
            {
                    monthlyPriceElement.innerText = `£${Number(monthlyPrice).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits:2 })}`;                    
            }
                    
        }
    }
    public static hideMonthlyPrice(monthlyPaymentLink: HTMLElement)
    {        
        if (monthlyPaymentLink !=null)
        {                    
            monthlyPaymentLink.classList.add("invisible");
        }
    }

    
}
