import { DepartmentPublicPhoneNumber } from "static/js/app/models/dealerInfo";

export function buildDealerContactPhoneDetails(dp: DepartmentPublicPhoneNumber): string {
return `<div class="contact-phone">
	<a href="tel: ${dp.phoneNumber}">
		<div class="contact-phone__label">
			<div class="field-name-label">
			${dp.label}
			</div>
		</div>
		<div class="contact-phone__phone">
			${dp.phoneNumber}
		</div>
	</a>
</div>`}
