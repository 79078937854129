import { AcceptedSearchTermSpec } from "static/js/app/models/acceptedSearchTermSpec";

export class SearchTermSpec {
    constructor(
        public fieldName: string,
        public fieldType: string,
        public rangeSearch: boolean,
        public sortable: boolean,
        public minRange: number,
        public maxRange: number,
        public acceptedSearchTerms: AcceptedSearchTermSpec[]
    ) { }
}