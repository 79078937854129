import * as http from "static/js/app/utils/http";

export let apiBaseUrl: string = null;

export function init(snApiBaseUrl: string) {
    apiBaseUrl = snApiBaseUrl;
}

export function buildGetJson<TDto, TReturn>(endpoint: string, map: (dto: TDto) => TReturn): () => Promise<TReturn> {
    return async () => {
        let url: string = buildUrl(endpoint);

        let json: string = await http.getJson(url);

        let dto: TDto = JSON.parse(json);

        return map(dto);
    }
}

export function buildGetArray<TDto, TReturn>(endpoint: string, map: (dto: TDto) => TReturn): () => Promise<TReturn[]> {
    return async () => {
        let url: string = buildUrl(endpoint);

        let json: string = await http.getJson(url);

        let jsonDto = JSON.parse(json);

        return Array.isArray(jsonDto)
            ? jsonDto.map(map)
            : [map(jsonDto)];
    }
}

export function buildPostJson<TDto, TReturnDto, TReturn>(endpoint: string, data: TDto, map: (dto: TReturnDto) => TReturn): () => Promise<TReturn> {
    return async () => {
        let url: string = buildUrl(endpoint);

        let jsonData: string = JSON.stringify(data);

        let resultJson: string = await http.postJson(url, jsonData);

        let resultDto: TReturnDto = JSON.parse(resultJson);

        return map(resultDto);
    }
}

function buildUrl(endpoint: string) {
    if (apiBaseUrl === null) {
        throw new Error("Api client not initialized. Please call init first.");
    }
    return `${apiBaseUrl}/${endpoint}`;
}
