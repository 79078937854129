import { LatLngBounds } from "leaflet";
import { Pin } from "themes/module_map/static/js/map";

export class DealerInfo {
    constructor
        (
            public dealer: Dealer,
            public branches: DealerBranch[]
        ) { }
}

export class Dealer {
    constructor
        (
            public dealerId: number,
            public name: string,
            public isActive: boolean,
            public addr1: string,
            public addr2: string,
            public town: string,
            public county: string,
            public postcode: string,
            public phoneNo: string,
            public mobileNo: string,
            public email: string,
            public websiteURL: string,
            public logoFileName: string,
            public logoURL: string,
            public contactTitle: string,
            public contactFirstname: string,
            public contactLastname: string,
            public photoBarColor: string
        ) { }
}

export class DealerBranch {
    constructor(
        public branchId: number,
        public dealerId: number,
        public name: string,
        public addr1: string,
        public addr2: string,
        public town: string,
        public county: string,
        public postcode: string,
        public email: string,
        public websiteURL: string,
        public phoneNo: string,
        public mobileNo: string,
        public faxNo: string,
        public vatNumber: string,
        public latitude: number,
        public longitude: number,
        public logoFileName: string,
        public logoURL: string,
        public departments: BranchDepartment[]
    ) { }
}

export class BranchDepartment {
    constructor(
        public departmentId: number,
        public name: string,
        public email: string,
        public openingHours: DepartmentOpeningHours[],
        public phoneNumbers: DepartmentPhoneNumber[]
    ) { }
}

export class DepartmentOpeningHours {
    constructor(
        public departmentOpeningHoursId: number,
        public dayOfWeek: string,
        public openingTime: string,
        public closingTime: string
    ) { }
}

export class DepartmentPhoneNumber {
    constructor(
        public departmentPhoneNumberId: number,
        public label: string,
        public phoneNo: string
    ) { }
}

export class DepartmentPublicPhoneNumber {
    constructor(
        public label: string,
        public phoneNumber: string
    ) { }
}

export class DealerBranchPublicInfo {
    public id: number = 0
    public name: string = ""
    public departments: DealerDepartmentPublicInfo[] = []
    public addr1: string = ""
    public addr2: string = ""
    public town: string = ""
    public county: string = ""
    public postcode: string = ""
    public latLong: [number, number] = [0,0]

    constructor(o: DealerBranchPublicInfo) {
        if(o == null) { return null }

        Object.assign(this, o);
    }

    public getMapPin(): Pin | null
    {
        return (this.latLong == null || this.latLong.length != 2 || this.latLong[0] <= 0)
            ? null
            : new Pin(
                new LatLngBounds(this.latLong, this.latLong), 
                this.name
            );
    }
}

export class DealerDepartmentPublicInfo {
    constructor(
        public name: string,
        public departmentalPhoneNumbers: DepartmentPublicPhoneNumber[]
    ) { }
}