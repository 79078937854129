import { VehicleImageUrls } from "static/js/app/models/vehicleImageUrls";
export function buildVehicleImage (images:VehicleImageUrls[], enableCustomVehicleImageRatio:boolean = false): string {
    if (images != null && images.length > 0) {
        if (enableCustomVehicleImageRatio) {
            let mainImage = images.slice(0,1).map(im=> `${im.i800x600}`).join("");
            let re = /i800x600/gi; 
            let newstr = mainImage.replace(re, "w800");
            return newstr;
        }
        else {
            let mainImage = images.slice(0,1).map(im=> `${im.i800x600}`).join("");
            return mainImage;
        }
    }
    else {
        return "/basemedia/placeholder.jpg"; 
    }
}
