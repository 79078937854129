export class SearchPager
{
    public static init(pagerEl: HTMLUListElement, currPage: number, totalPages: number, itemsPerPage: number|null, maxNumberedPages: number, baseUrl: string, relativeUnpagedUrl: string)
    {
        var fullUnpagedUrl = `${baseUrl.replace(/\/$/,"")}${relativeUnpagedUrl}`;
        SearchPager.createListItems(pagerEl, currPage || 1, totalPages, maxNumberedPages || 9, itemsPerPage, relativeUnpagedUrl);
        SearchPager.createRelLinks(currPage || 1, totalPages, itemsPerPage, fullUnpagedUrl);
    }

    private static createRelLinks(currPage: number, totalPages: number, itemsPerPage: number, fullUnpagedUrl: string) {
        if(totalPages > 1)
        {
            const prev_linkId = "prev_lnk";
            const next_linkId = "next_lnk"
            if(currPage > 1 && document.getElementById(prev_linkId) == null) {                            
                let lnk = document.createElement("link");
                lnk.id = prev_linkId;
                let href = SearchPager.addPagingToUrl(fullUnpagedUrl, itemsPerPage, currPage-1);
                lnk.rel = "prev";
                lnk.href = href;

                document.head.appendChild(lnk);
            }

            if(currPage < totalPages && document.getElementById(next_linkId) == null) {
                let lnk = document.createElement("link");
                lnk.id = next_linkId
                let href = SearchPager.addPagingToUrl(fullUnpagedUrl, itemsPerPage, currPage+1);
                lnk.rel = "next";
                lnk.href = href;

                document.head.appendChild(lnk);
            }
        }
    }

    private static createListItems(pager: HTMLUListElement, currPage: number, totalPages: number, maxNumberedPages: number, itemsPerPage: number, unpagedUrl: string) {
        if(totalPages == 1) {
            return;
        }

        var liEls = SearchPager.getLiEls(currPage, totalPages, maxNumberedPages, itemsPerPage, unpagedUrl);

        liEls.forEach(li => pager.appendChild(li));
    }

    private static createListItem(liClass: string, href: string, title: string, text: string) {
        var li = document.createElement("li");
        li.className = liClass;

        var lnk = document.createElement("a");
        lnk.href = href;
        lnk.title = title;
        lnk.textContent = text;

        li.appendChild(lnk)

        return li;
    };

    private static addPagingToUrl(unpagedUrl: string, perPage: number| null, page: number) {
        var finalChar = unpagedUrl[unpagedUrl.length-1];
        var joinChar = (unpagedUrl.indexOf("?") === -1) ? "?"
            : (finalChar !== "&" && finalChar !== "?") ? "&" : "";
        
        if (perPage == null || perPage == 0)
        {
            return `${unpagedUrl}${ (page != 1? `${joinChar}page=${page}`: "")}`;
        }        
        return `${unpagedUrl}${joinChar}items-per-page=${perPage}${ (page != 1? `&page=${page}`: "")}`;
    };

    private static getLiEls(currPage: number, totalPages: number, maxNumberedPages: number, itemsPerPage: number, unpagedUrl: string) {
        var indexedLiEls = [];

        if(currPage > 1) {
            const firstUrl = SearchPager.addPagingToUrl(unpagedUrl, itemsPerPage, 1);
            const prevUrl = SearchPager.addPagingToUrl(unpagedUrl, itemsPerPage, currPage-1);

            const firstLi = SearchPager.createListItem("arrow first", firstUrl, "Go to first page", "« first");
            const prevLi = SearchPager.createListItem("arrow prev", prevUrl, "Go to previous page", "‹ previous");

            indexedLiEls.push(firstLi);
            indexedLiEls.push(prevLi);
        }

        const minStartPoint = (totalPages - maxNumberedPages > 0) ? totalPages - maxNumberedPages : 1;
        const maxPagesEitherSide = Math.ceil(maxNumberedPages / 2);
        var startPage = (currPage > maxPagesEitherSide)
            ? (currPage < totalPages - maxPagesEitherSide)
                ? currPage - maxPagesEitherSide + 1 // go back from currpage
                : minStartPoint // go back from total pages
            : 1;

        for(var i=0; (i<Math.min(maxNumberedPages, totalPages) && startPage + i <= totalPages); i++) {
            var page = startPage + i;
            if(page !== currPage) {
                var li = SearchPager.createListItem("page-idx", SearchPager.addPagingToUrl(unpagedUrl, itemsPerPage, page), "Go to page " + page, page.toString());
                indexedLiEls.push(li);
            } else {
                var li = SearchPager.createListItem("current", "", "Go to current page", currPage.toString());
                indexedLiEls.push(li);
            }
        }

        if(currPage !== totalPages) {
            var nextLi = SearchPager.createListItem("arrow next", SearchPager.addPagingToUrl(unpagedUrl, itemsPerPage, currPage+1), "Go to next page", "next ›");
            indexedLiEls.push(nextLi);

            var lastLi = SearchPager.createListItem("arrow last", SearchPager.addPagingToUrl(unpagedUrl, itemsPerPage, totalPages), "Go to last page", "last »");
            indexedLiEls.push(lastLi);
        }

        return indexedLiEls;
    }
}
