import { HugoPopularMakesModuleConfig } from "themes/module_popular_makes/static/js/hugoPopularMakesModuleConfig";

export class PopularMakesModuleConfig {
    public static FromHugoConfig(
        hugoPopularMakesModuleConfig: HugoPopularMakesModuleConfig
    ): PopularMakesModuleConfig {
        if (hugoPopularMakesModuleConfig == null) {
            return new PopularMakesModuleConfig();// use defaults
        }

        return new PopularMakesModuleConfig();
    }
}