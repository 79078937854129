import { HugoModuleLatestVehiclesSliderConfig } from "themes/module_latest_vehicles_slider/static/js/latestVehiclesSliderHugoConfig"
import { HugoModuleSimilarVehiclesSliderConfig } from "themes/module_similar_vehicles_slider/static/js/similarVehiclesSliderHugoConfig"
import { HugoModulePageContentImageSliderConfig } from "themes/module_page_content_image_slider/static/js/pageContentImageSliderHugoConfig"
import { HugoFinanceConfig } from "themes/module_finance_plugin/static/js/hugoFinanceConfig"
import { HugoPopularMakesModuleConfig } from "themes/module_popular_makes/static/js/hugoPopularMakesModuleConfig"
import { HugoModuleVehicleBranchDetailsConfig } from "themes/module_vehicle_branch_details/static/js/hugoModuleVehicleBranchDetailsConfig"
import { HugoModuleSimilarVehiclesSliderUglyEnableModuleFlag } from "themes/module_similar_vehicles_slider/static/js/hugoModuleSimilarVehiclesSliderUglyEnableModuleFlag"
import { HugoModuleMapConfig } from "themes/module_map/static/js/mapConfig"
import { VehicleStatus } from "static/js/app/models/__index"

export class HugoSiteConfig {
    public dealer_name: string
    public seo: HugoSiteConfigSeo
    public snapi_baseurl: string
    public vehicle_type: string
    public module_latest_vehicles_slider: HugoModuleLatestVehiclesSliderConfig
    public module_similar_vehicles_slider: HugoModuleSimilarVehiclesSliderConfig
    public similarvehiclesslider: HugoModuleSimilarVehiclesSliderUglyEnableModuleFlag
    public module_page_content_image_slider: HugoModulePageContentImageSliderConfig
    public mainmenu: HugoMenuItem[]
    public menutop: HugoMenuTopConfig
    public vehicle_details_page: HugoVehicleDetailsPageConfig
    public search: HugoSearchConfig
    public finance: HugoFinanceConfig
    public latest_panel_behaviour: string
    public latest_panel_behaviour_show_monthly_price_on: string
    public module_popular_makes: HugoPopularMakesModuleConfig
    public module_vehicle_branch_details: HugoModuleVehicleBranchDetailsConfig
    public stickynav: boolean
    public map: HugoModuleMapConfig   
    public service_quotation_tool: ServiceQuotationToolConfig
    public enable_custom_vehicle_image_ratio: boolean

    constructor(o: HugoSiteConfig) {
        if(o == null) { return null }

        Object.assign(this, o);
    }
}

export class ServiceQuotationToolConfig {
    primaryServicesTitle: string;
    services?: (SqtService)[] | null;
    secondaryServicesTitle: string;
    secondaryServices?: (SqtService)[] | null;
}

export class SqtService {
    servicename: string;
    servicedescription: string;
    prices?: (SqtPrice)[] | null;
}

export class SqtPrice {
    price: string;
    min_engine_size?: number | null;
    max_engine_size: number;
    fuel_type: string;
}

export class HugoSiteConfigSeo {
    public seo_county: string
    public seo_town: string
}

export class HugoMenuItem {
    public Identifier: string | null
    public Name: string | null
    public ConfiguredURL: string | null
    public Weight: string | null
    public Parent: string | null
    public Children: HugoMenuItem[] | null
    public Menu: string | null
}

export class HugoMenuTopConfig {
    public hide_logo_when_expanded: boolean
}

export class HugoSearchConfig {
    public sort_by: string
    public sort_order: string
    public items_per_page: number
    public dom_element_id: string
    public search_price_increment: number
    public search_button_text: string
    public price_dropdown_default: string
    public monthly_price_increment?: number
    public isFinanceSearch ?:boolean
    public advanced: HugoAdvancedSearchConfig
    public enable_previous_next_buttons ?:boolean
    
}

export class HugoAdvancedSearchConfig {
    public enabled: boolean
    public advanced_search_button_text: string
    public advanced_search_collapse_button_text: string
    public default_state: "expanded" | "hidden"        
    public show_fields: HugoAdvancedSearchFieldsConfig[]
    public show_advanced_search_button?: boolean
    public vehicle_statuses ?: string[]
    public default_vehicle_status : string
    public override_order_of_vehicle_types ?: string
    constructor() {
        this.show_advanced_search_button = this.show_fields?.map(f=>f.default_state == "hidden").length == 0;
    }
}

export class HugoAdvancedSearchFieldsConfig {
    public label: string
    public display_order_weight: number
    public api_search_field_name: number
    public querystring_field_name: string
    public default_state: "expanded" | "hidden"
    public default_value: string
}

export class HugoVehicleDetailsPageConfig {
    public enable_sticky_enquire: boolean
    public save_button_basic_style_class: string
    public gallery_image_size: string
    public gallery_thumb_size: string
    public max_dealer_phone_numbers: number
}
