export class RunningCost {
    constructor(
        public fuel_type: string,
        public mpg: number,
        public annual_tax_cost: number,
        public fuelcost_per_litre: number = 0,
        public annual_mileage: number = 0,
        public monthly_tax_cost: number = 0,
        public monthly_fuel_cost: number = 0,
        public annual_fuel_cost: number = 0
    ) { }

}