export class SeoUrls {
    public static regexGearbox = "^\/(?<usedornew>used|new-vehicles)\/(?<vehicletype>[^\/]+)\/(?<gearboxtype>automatic|manual)\/?$";
    public static regexFueltype = "^\/(?<usedornew>used|new-vehicles)\/(?<vehicletype>[^\/]+)\/(?<fueltype>bi\-fuel|diesel|dual\-fuel|electric|hybrid|lpg|petrol)\/?$";
    public static regexColour = "^\/(?<usedornew>used|new-vehicles)\/(?<vehicletype>[^\/]+)\/(?<colour>black|blue|gold|green|grey|orange|purple|red|silver|white|yellow|beige|other)\/?$";
    public static regexBodyStyle = "^\/(?<usedornew>used|new-vehicles)\/(?<vehicletype>[^\/]+)\/(?<body>a\-class|adventure|american\-rv|atv|box\-van|c\-class|campervan|car\-derived\-van|caravan|chassis\-cab|classic|coach|combi\-van|commuter|convertible|coupe|crew\-van|curtain\-side|custom\-cruiser|dropside|e\-bike|enduro|estate|hatchback|high\-top|limousine|low\-profile|luton|micro\-motorhome|minibike|minibus|moped|motocrosser|motorcycle|motorhome|mpv|naked|panel\-van|pickup|roadster|saloon|scooter|sidecar|specialist\-vehicle|sports\-tourer|static\-caravan|super\-moto|super\-sports|suv|temperature\-controlled|three\-wheeler|tipper|tourer|touring|touring\-caravan|trail\-bike|trial\-bike|van\-conversion|vehicle\-transporter|welfare|wheelchair\-accessible\-vehicle|window\-van|3\-wheeler|ambulance|beaver\-tail|bus|camper\-van|cdv|chiller\-van|crew\-cab|crew\-cab\-drop\-side|crew\-cab\-tipper|double\-cab\-pickup|drop\-side\-tipper|drop\-side\-truck|freezer\-van|fridge\-van|hearse|kit\-car|luton\-van|lwb\-panel\-van|milk\-float|mpvestate|mwb\-panel\-van|pick\-up|pop\-top|recovery|rv|spider|spyder|swb\-panel\-van|tilt\-and\-slide|tipper\-truck|truck|van)\/?$";
    public static regexVehicleDetails = "^\/(?<usedornew>used|new-vehicles)\/(?<vehicletype>[^\/]+)\/(?<make>[^-\/]*)-(?<model>[^-\/]*)-(?<derivative>.*-)?(?<id>[0-9_]+)\/?$";
	public static regexVehicleMakeModel = "^\/(?<usedornew>used|new-vehicles)\/(?<vehicletype>(?!vehicle)[^\/]*)\/?(?<make>[^\/]*)\/?(?<model>[^\/]*)\/?$";
	
	public static getSeoUrlParms(urlPathname: string, isWebsectionOrBranchUrl: boolean = false): {[index: string]: string} {
		return (isWebsectionOrBranchUrl) ? SeoUrls.getWebsectionUrlParms(urlPathname) : SeoUrls.getSeoUrlParms_(urlPathname);
	}

	private static getWebsectionUrlParms(urlPathname: string): {[index: string]: string} {
		const websectionSlugifiedName = urlPathname.split('/').filter(s=>s.length > 0).pop();

        if(websectionSlugifiedName == "") {
            return {}; // default - no match
        } else {
			return { websection: websectionSlugifiedName };
		}
	}

    private static getSeoUrlParms_(urlPathname: string): {[index: string]: string} {
        var urlRegexes = [ this.regexGearbox, // gearbox
                this.regexFueltype, // fueltype
                this.regexColour, // colour
                this.regexBodyStyle, // body style
                this.regexVehicleDetails, // used vehicle details
                this.regexVehicleMakeModel // used vehicle listings
             ];
               
        // return first match
        for(var i=0; i<urlRegexes.length; i++)
        {
            var match = this.matchGroupedRegex(urlRegexes[i], urlPathname);
    
            if(match.matched === true) {
                return match.groups;
            }
        }
        
        return {}; // default (no match)
    }

    private static extractRegExGroupNames(regexStr: string): string[] {
		var groupNames = [];

		var reGroupNames = new RegExp(/(<[^>]+>)/g);
		var reGroupNamesMatch = reGroupNames.exec(regexStr);

		while(reGroupNamesMatch != null) {
			var groupName = reGroupNamesMatch[0].replace(/<|>/g, '');
			groupNames.push(groupName);
			
			reGroupNamesMatch = reGroupNames.exec(regexStr);
		};

		return groupNames;
	}

	private static matchGroupedRegex(regexStr: string, matchStr: string): { matched: boolean, originalMatch: RegExpExecArray | null, groups: {[index: string]: string} } {
		// replace group names with basic groups (index based) - chrome only just about supports named groups currently and other browsers don't at all
		var basicGroupRegexStr = regexStr.replace(/\?<[^>]+>/g, '');
		var re = new RegExp(basicGroupRegexStr, "g");
		var match = re.exec(matchStr); 

		var groupedMatch: { matched: boolean, originalMatch: RegExpExecArray | null, groups: {[index: string]: string} } = {
			matched: (match !== null),
			originalMatch: match,
			groups: {}
		};

		var groupNames = this.extractRegExGroupNames(regexStr);
		
		for(var i=1; i<=groupNames.length; i++) {
			var grpName = groupNames[i-1];
		
			groupedMatch.groups[grpName] = (match !== null && match[i] != null && match[i].length > 0) ? decodeURI(match[i]) : '' ;
		}

		return groupedMatch;
	}
}

