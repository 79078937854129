import * as asyncUtil from "static/js/app/utils/asyncUtil";
import * as snSvrApi from "static/js/app/api/snSvrApi";
import * as DtoMapping from "static/js/app/api/dtoMapping";
import * as Models from "static/js/app/models/__index";

export async function vrmlookup(
    vrm: Models.VRMRequest,
    callGroupOptions?: Models.CallGroupOptions): Promise<Models.VRMResult> {
    snSvrApi.init("/vrm");
    return await asyncUtil.addCancelation<Models.VRMResult>(
        snSvrApi.buildPostJson(
            ``,
            vrm,
            DtoMapping.VrmResultDtoToVrmResult
        ),
        callGroupOptions)();
};

export async function ConfigureImageUpload(
    requestModel: Models.PartExchangeImageFile[],
    callGroupOptions?: Models.CallGroupOptions): Promise<Models.PartExchangeImageFile[]> {
    snSvrApi.init("/px");
    return await asyncUtil.addCancelation<Models.PartExchangeImageFile[]>(
        snSvrApi.buildPostJson(
            `uploadphotos`,
            requestModel,
            DtoMapping.PartExchageImageFileDtoArrayToPartExchageImageFileArray
        ),
        callGroupOptions)();
};
