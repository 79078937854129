export default class SlickVehicleGallery {
    public static init() {
		const autoPlay = ($('.phyron-thumbnail').length > 0 ? false: true);
		if ($('.slick--vehicle-gallery-main').length > 0) {
			$('.slick--vehicle-gallery-main.image-carousel').slick({
				slidesToScroll: 1,
				arrows: true,
				prevArrow: "<span class='slick-prev main'>&lt;</span>",
				nextArrow: "<span class='slick-next main'>&gt;</span>",
				autoplay: autoPlay,
				centerMode: true,
				variableWidth: true,
				focusOnSelect: true,
				responsive: [
					{
						breakpoint: 755,
						settings: {
							centerMode: false,
							variableWidth: false,
							autoplay: false
						}
					}
				],
			});
			$('.slick--vehicle-gallery-main.big-image').slick({
				slidesToScroll: 1,
				arrows: true,
				prevArrow: "<span class='slick-prev main'>&lt;</span>",
				nextArrow: "<span class='slick-next main'>&gt;</span>",
				// asNavFor: '.slick--vehicle-gallery-thumbs',
				autoplay: autoPlay,
				centerMode: false,
				variableWidth: false
			});
			$('.slick--vehicle-gallery-main.smaller-image').slick({
				slidesToScroll: 1,
				arrows: true,
				prevArrow: "<span class='slick-prev main'>&lt;</span>",
				nextArrow: "<span class='slick-next main'>&gt;</span>",
				// asNavFor: '.slick--vehicle-gallery-thumbs',
				autoplay: autoPlay,
				centerMode: false,
				variableWidth: false
			});
			// $('.slick--vehicle-gallery-thumbs').slick({
			// 	slidesToShow: 3,
			// 	slidesToScroll: 1,
			// 	arrows: true,
			// 	prevArrow: "<span class='slick-prev thumbs'>&lt;</span>",
			// 	nextArrow: "<span class='slick-next thumbs'>&gt;</span>",
			// 	asNavFor: '.slick--vehicle-gallery-main',
			// 	dots: false,
			// 	centerMode: true,
			// 	centerPadding: '0px',
			// 	focusOnSelect: true,
			// 	autoplay: false
			// });
			$('.slick--vehicle-gallery-main').each(function () {
				(<any>$(this)).magnificPopup({
					delegate: '.slick-slide:not(.slick-cloned) a',
					type: 'image',
					gallery: {
					  enabled: true,
					  preload: [0,1] // Will preload 0 - before current, and 1 after the current image
					},
					callbacks: {
						elementParse: function(item:any) {
						  console.log(item.el[0].className);
						  if(item.el[0].className == 'video') {
							item.type = 'iframe'
						  } else {
							 item.type = 'image'
						  }
					
						}
					  }
				});
			});
			// $('.slick--vehicle-gallery-thumbs').each(function () {
			// 	(<any>$(this)).magnificPopup({
			// 		delegate: '.slick-slide:not(.slick-cloned) a',
			// 		type: 'iframe',
			// 		gallery: {
			// 			enabled: false
			// 		}
			// 	});
			// });
		}
	}
}