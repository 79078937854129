import { Vehicle } from "static/js/app/models/vehicle";

export function buildVehicleMonthlyPayment(vehicle: Vehicle): string  
{
    if (vehicle.monthly_payment == null || vehicle.monthly_payment == 0)
    {
        return '';
    }
return `<a href="${vehicle.urlPath}#vehicle-finance">
        <div class="field field-name-fmg-quoteware-api-payment field-name-fmg-quoteware-api-payment--used-vehicle field-name-fmg-quoteware-api-payment--search-result" style="display: block;">
            <div class="label-inline">Monthly From </div>£${Number(vehicle.monthly_payment).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits:2 })}</div></a>`;
};