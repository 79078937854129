import { VehicleStatus } from "static/js/app/models/__index";
import { Availability } from "static/js/app/models/__index";

export class GetSearchTermsOptions {
    constructor(
        public vehicleStatus: VehicleStatus = "used",
        public vehicleType: string = "any",
        public availability: Availability = "available"
        
    ) { }
}