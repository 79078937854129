import { Page } from "static/js/app/modules/page";
import "slick-carousel";

import * as Api from "static/js/app/api/endpoints";

import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import * as Models from "static/js/app/models/__index";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";

import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import ListingHelpers from "static/js/app/templateHelpers/ListingsHelpers";
import { SearchConfig } from "static/js/app/modules/search";

export default class SearchPage {
    private static searchUrlGenerator: (search: Models.VehicleSearch) => string;
    private static crumbs: Models.BreadcrumbEntry[];
    private static sortTerms: [{ fieldName: string, isDescending: boolean }];

    private static pageNumber: number;
    private static itemsPerPage: number;
    private static sortBy: string;
    private static order: string;
    private static defaultVehicleType: string;
    private static defaultVehicleTypeDisplay: string;
    private static financeSearchTerms: Models.FinanceSearchTerms;

    public static async init(siteConfig: SiteConfig, pageConfig:PageConfig, onTemplatesLoaded: () => void = () => undefined, vehicleStatus: Models.VehicleStatus = "used", availability: Models.Availability = "available" ) {
        SearchPage.defaultVehicleTypeDisplay = siteConfig.vehicleType + "s";
        SearchPage.defaultVehicleType = siteConfig.vehicleType.toLowerCase();
        SearchPage.searchUrlGenerator = (search: Models.VehicleSearch) => Page.getSearchUrl(search, true);

        const searchPerformed = await ListingHelpers.convertQueryStringToVehicleSearchPerformed(Page.queryString, availability,vehicleStatus, SearchPage.defaultVehicleTypeDisplay);
        const searchTerms = ListingHelpers.getSearchTerms();

        let relativeRootSearchUrl = (availability == "sold") ? "/sold-gallery/" : (vehicleStatus ==  "new" ? "/new-vehicles/": "/used/");
       
        SearchPage.crumbs = Breadcrumbs.getSearchBreadcrumbs(relativeRootSearchUrl, searchPerformed, SearchPage.searchUrlGenerator);
        let sortTerm = ListingHelpers.getSortOrder(siteConfig.searchConfig.sort_by, siteConfig.searchConfig.sort_order);
        SearchPage.sortBy = sortTerm.sortBy;
        SearchPage.order = sortTerm.sortOrder;
        
        SearchPage.pageNumber = (parseInt(Page.queryString["page"]) || 1);
        SearchPage.itemsPerPage = (parseInt(Page.queryString["items-per-page"]) || siteConfig.searchConfig.items_per_page);
        
        SearchPage.financeSearchTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
        SearchPage.sortTerms = ListingHelpers.getSortTerms(SearchPage.sortBy, SearchPage.order);

        let searchCriteria = new Models.SearchCriteria(searchTerms, undefined, SearchPage.sortTerms, SearchPage.pageNumber, SearchPage.itemsPerPage, availability, vehicleStatus, SearchPage.financeSearchTerms, siteConfig?.searchConfig?.enable_previous_next_buttons);
        
        const searchResults = await Api.Vehicles.search(searchCriteria);
        ListingHelpers.setSearchResultUrls(searchResults.advertUrls);
        ListingHelpers.updatePageTitleAndMetaWhenReady(siteConfig, searchPerformed, SearchPage.defaultVehicleTypeDisplay, availability, "main header h1", searchResults.total,SearchPage.crumbs);

        Page.ready(() => {
            // only act on non root level /used/blah... pages
            // because the root "/used/" url has a noindex meta tag added by the edge lambda
            if (window.location.pathname != '/used/' && window.location.pathname != '/used') {
                const isCanonicalSearchUrl = (
                    Page.queryString['minprice'] == undefined || Page.queryString['minprice'] || null || Page.queryString['minprice'].length == 0 ||
                    Page.queryString['maxprice'] == undefined || Page.queryString['maxprice'] || null || Page.queryString['maxprice'].length == 0 ||
                    Page.queryString['minmonlthypayment'] == undefined || Page.queryString['minmonlthypayment'] || null || Page.queryString['minmonlthypayment'].length == 0 ||
                    Page.queryString['maxmonthlypayment'] == undefined || Page.queryString['maxmonthlypayment'] || null || Page.queryString['maxmonthlypayment'].length == 0
                );

                if (isCanonicalSearchUrl) {
                    let canonicalTag = document.createElement('link');
                    canonicalTag.rel = 'canonical';
                    canonicalTag.href = `${window.location}`;
                    canonicalTag.id = 'canonical';
                    document.head.appendChild(canonicalTag);
                } else {
                    let robotsNoindexTag = document.createElement('meta');
                    robotsNoindexTag.name = 'robots';
                    robotsNoindexTag.content = 'noindex, follow';
                    document.head.appendChild(robotsNoindexTag);
                }
            }

            ListingHelpers.setSortByOptions(SearchPage.sortBy, SearchPage.itemsPerPage, SearchPage.order);
            VehicleFinanceQuotes.setFinanceSearchTerms(SearchPage.financeSearchTerms);

            const priceFilter = searchTerms.filter(s=>s.fieldName == "price")[0];
            const monthlyPriceFilter = searchTerms.filter(s=>s.fieldName == "finance_quotes.monthly_payment")[0];
            ListingHelpers.setHiddenFilterOptions(priceFilter.targetRangeMin, priceFilter.targetRangeMax, monthlyPriceFilter.targetRangeMin, monthlyPriceFilter.targetRangeMax);
            ListingHelpers.configureSearchFilterEvents();

            const gridTemplate = "searchResultsTemplateGrid";
            const rowTemplate = "searchResultsTemplateRows";
            const defaultTemplate = "searchResultsTemplate";

            const templateId = (document.getElementById(gridTemplate) != null ? gridTemplate: defaultTemplate);

            ListingHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, relativeRootSearchUrl, siteConfig.financeConfig, siteConfig.dealerBranches, templateId);
            
            const gridLayoutOption = document.getElementById("layoutOptionGrid");
            if (gridLayoutOption != null)
            {
                gridLayoutOption.onclick = function layoutOptionGrid() {
                ListingHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, relativeRootSearchUrl, siteConfig.financeConfig, siteConfig.dealerBranches, gridTemplate);
                const gridElement = document.getElementById('searchResults');
                gridElement.classList.add('grids');
                gridElement.classList.remove("rows");
                gridLayoutOption.classList.add('selected');
                rowsLayoutOption.classList.remove('selected');
                }
            }
            const rowsLayoutOption = document.getElementById("layoutOptionRows");
            if (rowsLayoutOption !=null)
            {
                rowsLayoutOption.onclick = function layoutOptionGrid() {
                ListingHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, relativeRootSearchUrl, siteConfig.financeConfig, siteConfig.dealerBranches,rowTemplate);
                 const rowElement = document.getElementById('searchResults');
                 rowElement.classList.add('rows');
                 rowElement.classList.remove("grids");
                 gridLayoutOption.classList.remove('selected');
                 rowsLayoutOption.classList.add('selected');
             }
            }
            
            if (onTemplatesLoaded != null) {
                onTemplatesLoaded();
            }

            ListingHelpers.initializePager(
                searchResults.totalPages,
                SearchPage.pageNumber,
                (Page.queryString["items-per-page"] != null ? parseInt(Page.queryString["items-per-page"]): null),
                Page.queryString["sort-by"],
                Page.queryString["order"],
                `${window.location.protocol}//${window.location.host}`,
                relativeRootSearchUrl
            );   
        });
    }
    
}



