import { QuickReplace } from "static/js/app/utils/QuickReplace";
import * as Api from "static/js/app/api/endpoints";
import DOMPurify from "dompurify";
import { SearchCriteria, SortTerm, VehicleSearchResult, Vehicle } from "static/js/app/models/__index";
import { ModuleSimilarVehiclesConfig } from "themes/module_similar_vehicles_slider/static/js/similarVehiclesConfig";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import { Page } from "static/js/app/modules/page";

export default class SimilarVehicles {
    public static init(config: ModuleSimilarVehiclesConfig, vehicles: Vehicle[]) {
        if (vehicles.some(vehicle => vehicle.main_image != null && vehicle.main_image[config.imageSize] != null)) {
            Page.ready(function () {
                const vehiclesTemplate = document.getElementById(config.domTemplateElementId);

                if (vehiclesTemplate) {
                    SimilarVehicles.generateSimilarVehiclesHtml(vehicles, vehiclesTemplate, config);

                    // slick
                    const slickBody = $(`#${config.domElementId}`);
                    const slickSettings = JSON.parse(config.slickSettings);
                    slickBody.slick(slickSettings);

                    // show section titles
                    const slickHead = slickBody.siblings("h2");
                    slickHead.removeClass("hide");

                    // show section titles
                    const slickHeadAlt = slickBody.siblings("div .section-title").find("h2").filter(":first");
                    slickHeadAlt.removeClass("hide");
                }
            });
        }
    }

    private static generateSimilarVehiclesHtml(vehicles: Vehicle[], template: HTMLElement, config: ModuleSimilarVehiclesConfig) {
        // Get the contents of the template
        const templateHtml = template.innerHTML;
        // Final HTML variable as empty string
        let listHtml = "";

        // regex creation is reasonably expensive, cache the regex built for repeated templates like this
        let cachedRegEx: RegExp = null;
        const regExCacher = function (regex: RegExp) {
            // return the cachced regex if no regex is supplied, or cache it and return it if it is supplied
            if (regex !== null) {
                cachedRegEx = regex;
            }
            return cachedRegEx;
        };

        // Loop through items, replace placeholder tags
        // with actual data, and generate final HTML
        for (let i = 0; i < vehicles.length; i++) {
            if (vehicles[i].main_image != null && vehicles[i].main_image[config.imageSize] !== null) {
                const price: string = (vehicles[i].price > 0)
                    ? "£" + Number(vehicles[i].price).toLocaleString(undefined, { maximumFractionDigits: 0 })
                    : "POA";

                const vatExempt: string = (vehicles[i].vat_exempt == true)
                    ? "<div class=\"field-name-field-vehicle-vat\">No VAT</div>"
                    : "";

                const vatExcluded: string = (vehicles[i].vat_excluded == true)
                    ? "<div class=\"field-name-field-vehicle-vat\">Plus VAT</div>"
                    : "";
                var monthly_payment = (vehicles[i].monthly_payment > 0) ? 
                    `<div class="field field-name-field-vehicle-monthly-price">£${vehicles[i].monthly_payment}  <span class="label-inline">PM</span></div></div>`
                    : "";
                // string replacements to perform
                const replacements = {
                    "%vehicle_id%": vehicles[i].id,
                    "%vehicle_photo%": (vehicles[i].main_image != null) ? vehicles[i].main_image[config.imageSize] : "/awaitingimage.jpg",
                    "%vehicle_type%": (vehicles[i].vehicle_type && vehicles[i].vehicle_type.length) ? vehicles[i].vehicle_type.toLowerCase() : "",
                    "%vehicle_type_plural%": (vehicles[i].vehicle_type && vehicles[i].vehicle_type.length) ? vehicles[i].vehicle_type.toLowerCase() + "s" : "",
                    "%vehicle_make%": vehicles[i].manufacturer,
                    "%vehicle_make_display%": vehicles[i].manufacturer_display,
                    "%vehicle_model%": vehicles[i].model,
                    "%vehicle_model_display%": vehicles[i].model_display,
                    "%vehicle_derivative%": StringFormatting.coerceNullishToBlank(vehicles[i].derivative),
                    "%vehicle_derivative_display%": StringFormatting.coerceNullishToBlank(vehicles[i].derivative_display),
                    "%vehicle_price%": price,
                    "%vehicle_monthly_price%": monthly_payment,
                    "%vehicle_year%": StringFormatting.coerceNullishToBlank(vehicles[i].year_built),
                    "%vehicle_fuel%": StringFormatting.coerceNullishToBlank(vehicles[i].fuel_type_display),
                    "%vehicle_transmission%": StringFormatting.coerceNullishToBlank(vehicles[i].transmission_display),
                    "%vehicle_mileage%": StringFormatting.coerceNullishToBlank(vehicles[i].mileage),
                    "%vehicle_mpg%": StringFormatting.coerceNullishToBlank(vehicles[i].mpg.toString()),
                    "%vehicle_vat_exempt%": vatExempt,
                    "%vehicle_vat_excluded%": vatExcluded,
                    "%vehicle_details_url%": vehicles[i].urlPath
                };

                // do replacements (using regex caching)
                listHtml += QuickReplace.quickReplace(replacements, templateHtml, regExCacher);
            }
        }

        // Replace the HTML of #searchResults with final HTML
        document.getElementById(config.domElementId).innerHTML = DOMPurify.sanitize(listHtml);
    }
}
