import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import * as Api from "static/js/app/api/endpoints";

import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import * as Models from "static/js/app/models/__index";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";

import { PageConfig } from "static/js/app/hugoSettings/PageConfig";

import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";


import ListingsHelpers from "static/js/app/templateHelpers/ListingsHelpers";

export default class WebsectionGallery {
    private static sortTerms: { fieldName: string, isDescending: boolean }[];

    private static pageNumber: number;
    private static itemsPerPage: number;
    private static sortBy: string;
    private static order: string;
    private static financeSearchTerms: Models.FinanceSearchTerms;

    public static async init(siteConfig: SiteConfig, pageConfig: PageConfig, onTemplatesLoaded: () => void = null, availability: Models.Availability = "available", vehicleStatus: Models.VehicleStatus = "all") {
        const websectionSlugifiedName = document.location.pathname.split('/').filter(s=>s.length > 0).pop();

        if(websectionSlugifiedName == "") {
            return;
        }

        
        let sortOrder = ListingsHelpers.getSortOrder(siteConfig.searchConfig.sort_by,siteConfig.searchConfig.sort_order );
        WebsectionGallery.sortBy = sortOrder.sortBy;
        WebsectionGallery.order = sortOrder.sortOrder;
        WebsectionGallery.pageNumber = (parseInt(Page.queryString["page"]) || 1);
        WebsectionGallery.itemsPerPage = (parseInt(Page.queryString["items-per-page"]) || siteConfig.searchConfig.items_per_page);
       
        WebsectionGallery.sortTerms = ListingsHelpers.getSortTerms(WebsectionGallery.sortBy, WebsectionGallery.order);
        WebsectionGallery.financeSearchTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
        const searchTerms = [ new Models.SearchTerm("websection", websectionSlugifiedName) ];

        let searchCriteria = new Models.SearchCriteria(
            searchTerms, null, WebsectionGallery.sortTerms,
            WebsectionGallery.pageNumber, WebsectionGallery.itemsPerPage, availability, vehicleStatus, WebsectionGallery.financeSearchTerms);

        const searchResults = await Api.Vehicles.search(searchCriteria);

        Page.ready(() => {
            ListingsHelpers.synchronizeSearchInputs();
            VehicleFinanceQuotes.setFinanceSearchTerms(WebsectionGallery.financeSearchTerms);
            ListingsHelpers.setSortByOptions(WebsectionGallery.sortBy, WebsectionGallery.itemsPerPage, WebsectionGallery.order);

            Breadcrumbs.setBreadcrumbs(Breadcrumbs.getGalleryBreadcrumbs(document.location.pathname, pageConfig.title));

            ListingsHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, "/used/", siteConfig.financeConfig,siteConfig.dealerBranches);

            if (onTemplatesLoaded != null) {
                onTemplatesLoaded();
            }
            ListingsHelpers.configureSearchFilterEvents();
            ListingsHelpers.initializeDefaultPager(
                searchResults.totalPages,
                WebsectionGallery.pageNumber,
                (Page.queryString["items-per-page"] != null ? parseInt(Page.queryString["items-per-page"]): null),
                `${window.location.protocol}//${window.location.host}`
            );
        });
    }
}
