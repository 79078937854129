export default class PageSlideshow {
    public static init(domString: string) {
        $(domString).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: "<span class='slick-prev main'>&lt;</span>",
            nextArrow: "<span class='slick-next main'>&gt;</span>",
            autoplay: true,
            autoplaySpeed: 3000
        });
    }
}