import { Vehicle } from "static/js/app/models/vehicle";

export class VehicleSearchResult {
    constructor(
        public pageNumber: number,
        public pageSize: number,
        public total: number,
        public results: Vehicle[],
        public totalPages: number,
        public advertUrls: string[] | null
    ) { }
}