import { MenuItem } from "static/js/app/modules/menuItem";
import * as Api from "static/js/app/api/endpoints";
import { GetModelsOptions, VehicleCount, SearchTerm, SearchCriteria, Availability } from "static/js/app/models/__index";

import { SeoUrls } from "static/js/app/modules/seoUrls";

export default class MainMenu {
    public static init(menuElSelector: string, mainMenuItems: MenuItem[]) {
        const menusWithChildren = mainMenuItems.filter((menuItem) => menuItem.children?.length);
        const menusWithLinksAndNoChildren = mainMenuItems.filter((menuItem) => menuItem.url != "" && (menuItem.children == null || menuItem.children?.length == 0));
        let hideMenuItems: MenuItem[] = [];
        const getVehicleCountsTasks:Promise<MenuItem[]>[] = [];
        let menuItemsToHideWhenNoStock:MenuItem[] = [];

        menusWithLinksAndNoChildren.forEach(menuItem => {
            // add menu item
            if(menuItem.isOptional() && menuItem.isStockSearch()) {
                menuItemsToHideWhenNoStock.push(menuItem);
            }
        });

        // menus that have submenus
        menusWithChildren.forEach(parentMenu => {
            // add children hide items
            menuItemsToHideWhenNoStock = menuItemsToHideWhenNoStock.concat((parentMenu.children ?? [])
                .filter(menuItem => menuItem.isOptional() && menuItem.isStockSearch()));
        });

        const getVehicleCountsTask = this.getStockMenuItemsWithCounts(menuItemsToHideWhenNoStock)
        .then((menuItemsAndCounts) => { 
            hideMenuItems = hideMenuItems.concat(
                (menuItemsAndCounts.filter((menuItemCount) => menuItemCount.count == 0).map(h => h.menuItem))
            );

            return hideMenuItems;
        });

        getVehicleCountsTasks.push(getVehicleCountsTask);

        Promise.all(getVehicleCountsTasks).then(() => {
            MainMenu.showAllMenuItemsExceptTheseWhenReady(menuElSelector, hideMenuItems);
        });        
    }

    private static async getVehicleCount(menuItem: MenuItem): Promise<number | null> {
        if(!menuItem.isOptional() || !menuItem.isStockSearch()) {
            return null;
        }

        const urlParams = (menuItem.url != null) ? SeoUrls.getSeoUrlParms(menuItem.url, (menuItem.isWebsectionStockSearch() || menuItem.isBranchStockSearch())) : {};

        const urlParamSearchParamNamesLookup = [
            new UrlParamSearchParamNameLookupItem("vehicletype", "vehicle_type"),
            new UrlParamSearchParamNameLookupItem("make", "manufacturer"),
            new UrlParamSearchParamNameLookupItem("model", "model"),
            new UrlParamSearchParamNameLookupItem("body", "body_type"),
            new UrlParamSearchParamNameLookupItem("colour", "basic_colour"),
            new UrlParamSearchParamNameLookupItem("fueltype", "fuel_type"),

            new UrlParamSearchParamNameLookupItem("berth", "berth"),
            new UrlParamSearchParamNameLookupItem("doors", "doors"),
            new UrlParamSearchParamNameLookupItem("insurance_group", "insurance-group"),
            new UrlParamSearchParamNameLookupItem("keywords", "keywords"),
            new UrlParamSearchParamNameLookupItem("year_built", "year"),
            new UrlParamSearchParamNameLookupItem("vehicles", "vehicles"),
            new UrlParamSearchParamNameLookupItem("branch", "branch"),

            new UrlParamSearchParamNameLookupItem("gearboxtype", "transmission"),

            new UrlParamSearchParamNameLookupItem("websection", "websection"),
        ];

        var searchTerms = this.getSearchTerms(urlParams, urlParamSearchParamNamesLookup);

        if(searchTerms.length == 0 && !menuItem.isSoldStockSearch())
        {
            return null; // not a vehicle search url
        }

        // search with zero items per page to just get search results count
        let availability:Availability = (menuItem.isSoldStockSearch()) ? "sold" : "available";
        const criteria = new SearchCriteria(searchTerms, undefined, undefined, 0, 0, availability);
        const results = await Api.Vehicles.search(criteria);
        return results.total;
    }

    private static getSearchTerms(urlParams: { [index: string]: string; }, urlParamSearchParamNamesLookup: UrlParamSearchParamNameLookupItem[]) {
        if (Object.keys(urlParams).length == 0) {
            return [];
        }

        return urlParamSearchParamNamesLookup.map(paramMap => {
            const searchValue = (paramMap.urlParamName == "vehicletype")
                ? urlParams[paramMap.urlParamName]?.replace(/s$/, "")
                : urlParams[paramMap.urlParamName];
            return new SearchTerm(paramMap.searchParamName, searchValue);
        });
    }

    private static async getStockMenuItemsWithCounts(menuItems: MenuItem[]): Promise<MenuItemAndCount[]> {
        const menuItemsWithCounts = await Promise.all(
            menuItems.map(
                async (menuItem) => new MenuItemAndCount(menuItem, ((await this.getVehicleCount(menuItem)) ?? 0))
            ).filter((menuItemAndCount, index, menuItems) => 
                menuItems.indexOf(menuItemAndCount) == index) // de-duplicate
        );

        return menuItemsWithCounts.filter(menuItemAndCount => menuItemAndCount.count != null); // remove non vehicle urls (ones with no vehicle count) from list
    }

    private static showAllMenuItemsExceptTheseWhenReady(menuElSelector: string, hiddenMenuItems: MenuItem[]) {
        const whenReady: FrameRequestCallback = (timestamp: number) => {
            const menuEl = document.querySelector(menuElSelector) as HTMLElement;

            if (menuEl == null) {
                window.requestAnimationFrame(whenReady);
            } else {
                MainMenu.showAllMenuItemsExceptThese(menuEl, hiddenMenuItems);
            }
        }

        window.requestAnimationFrame(whenReady);
    }

    private static showAllMenuItemsExceptThese(menuEl: HTMLElement, hiddenMenuItems: MenuItem[]) {
        // use the hidden menu items to control hiding
        const menuItems = menuEl.querySelectorAll("li");

        if (menuItems != null) {
            for(let i=0; i<menuItems.length; i++) {
                const menuItem = menuItems[i];
                const hyperlink = menuItem.querySelector("a[href]") as HTMLAnchorElement;

                if (hyperlink != null && !(hiddenMenuItems.some((hiddenItem) => hiddenItem.url == new URL(hyperlink.href).pathname))) {
                    menuItem.classList.remove("hide");
                }
            }
        }
    }
}

class MenuItemAndCount {
    constructor(
        public menuItem: MenuItem,
        public count: number
    ) {}
}

class UrlParamSearchParamNameLookupItem {
    constructor(
        public urlParamName: string,
        public searchParamName: string
    ) {}
}