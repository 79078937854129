//#region imports
import { VehicleSearchPerformed, VehicleSearch, Vehicle, BreadcrumbEntry } from "static/js/app/models/__index";
import { Page } from "static/js/app/modules/page";
//#endregion

export class Breadcrumbs {
    public static elDomSelector = "ul.breadcrumbs";

    public static init(elDomSelector: string = "ul.breadcrumbs") {
        this.elDomSelector = elDomSelector;
    }

    public static getSearchBreadcrumbs(rootSearchUrl: string, vehicleSearchPerformed: VehicleSearchPerformed, getSearchUrl: (search: VehicleSearch, resetSortAndPage?:boolean) => string) {
        var fn = (!Breadcrumbs.nullOrEmpty(vehicleSearchPerformed.fuelType)) ? Breadcrumbs.getFuelTypeCrumbs :
            (!Breadcrumbs.nullOrEmpty(vehicleSearchPerformed.bodyType)) ? Breadcrumbs.getBodyTypeCrumbs :
                (!Breadcrumbs.nullOrEmpty(vehicleSearchPerformed.gearbox)) ? Breadcrumbs.getGearboxTypeCrumbs :
                    (!Breadcrumbs.nullOrEmpty(vehicleSearchPerformed.basicColour)) ? Breadcrumbs.getColourCrumbs :
                        (!Breadcrumbs.nullOrEmpty(vehicleSearchPerformed.model)) ? Breadcrumbs.getModelCrumbs :
                            (!Breadcrumbs.nullOrEmpty(vehicleSearchPerformed.manufacturer)) ? Breadcrumbs.getMakeCrumbs :
                                (!Breadcrumbs.nullOrEmpty(vehicleSearchPerformed.vehicleType)) ? Breadcrumbs.getvehicleTypeCrumbs
                                    : Breadcrumbs.getHomeCrumbs;
        return fn(rootSearchUrl, vehicleSearchPerformed, getSearchUrl);
    }

    public static adjustBreadcrumbsForErrorPage(urlPath: string) {
        const regex = /\/used\/([^\/]+)\//;
        const groups = regex.exec(urlPath);
        if(groups != null && groups.length > 0) {
            const vehicleType = groups[1];
            
            const breadcrumbsEl = document.querySelector(this.elDomSelector);

            const finalItem = breadcrumbsEl?.children[breadcrumbsEl.children.length - 1];

            if(finalItem != null) {
                const vehicleTypeSearchLinkLi = finalItem.cloneNode(true) as HTMLLIElement;
                const vehicleTypeSearchLink = vehicleTypeSearchLinkLi.children[0] as HTMLAnchorElement;
                vehicleTypeSearchLink.href = `/used/${vehicleType}/`;
                vehicleTypeSearchLink.innerText = `Used ${vehicleType[0].toUpperCase()}${vehicleType.slice(1)}`;

                breadcrumbsEl.insertBefore(vehicleTypeSearchLinkLi, finalItem);

                // and finally remove the link from the last element ("Sorry")
                finalItem.innerHTML = finalItem.children[0].innerHTML;
            }
        }
    }

    public static setBreadcrumbs(breadcrumbs: BreadcrumbEntry[]) {
        if (breadcrumbs && breadcrumbs.length > 0) {
            var breadcrumbsEl = document.querySelector(this.elDomSelector);
            while (breadcrumbsEl.lastChild) {
                breadcrumbsEl.removeChild(breadcrumbsEl.lastChild);
            }

            var listItems = Breadcrumbs.buildListItems(breadcrumbs);

            listItems.forEach((li: HTMLLIElement) => {
                breadcrumbsEl.appendChild(li);
            });

            breadcrumbsEl.classList.remove("invisible");
        }
    }

    public static displayBreadcrumbs() {
        Page.ready(() => {
            var breadcrumbsEl = document.querySelector(this.elDomSelector);
            
            if (breadcrumbsEl != null && breadcrumbsEl.children != null && breadcrumbsEl.children.length > 0) {
                breadcrumbsEl.classList.remove("invisible");
            }
        });
    }

    public static getGalleryBreadcrumbs(galleryUrl: string, galleryName: string): BreadcrumbEntry[] {
        var crumbs = Breadcrumbs.getHomeCrumbs();

        crumbs.push(
            { title: galleryName, link: galleryUrl }
        );

        return crumbs;
    }


    private static nullOrEmpty(str: string) {
        return (str === undefined || str === null || str.length === 0);
    }

    private static getHomeCrumbs(): BreadcrumbEntry[] {
        var crumbs = [
            { title: 'Home', link: '/' },
        ];

        return crumbs;
    }

    private static getvehicleTypeCrumbs(rootSearchUrl: string, VehicleSearchPerformed: VehicleSearchPerformed, getSearchUrl: (search: VehicleSearch, resetSortAndPage?:boolean) => string): BreadcrumbEntry[] {
        var crumbs = Breadcrumbs.getHomeCrumbs();

        if (Breadcrumbs.nullOrEmpty(VehicleSearchPerformed.vehicleType)) {
            return crumbs;
        }

        const search = new VehicleSearch(rootSearchUrl);
        search.rootUrl = rootSearchUrl;
        search.vehicleType = (VehicleSearchPerformed.vehicleType || '').toLowerCase();

        var link = getSearchUrl(search, true);

        crumbs.push({ title: `${(VehicleSearchPerformed.vehicleStatus == "new"? "New" : "Used")} ${VehicleSearchPerformed.vehicleTypeDisplay}`, link: link });

        return crumbs;
    }

    private static getMakeCrumbs(rootSearchUrl: string, VehicleSearchPerformed: VehicleSearchPerformed, getSearchUrl: (search: VehicleSearch, resetSortAndPage?:boolean) => string): BreadcrumbEntry[] {

        var crumbs = Breadcrumbs.getvehicleTypeCrumbs(rootSearchUrl, VehicleSearchPerformed, getSearchUrl);

        if (Breadcrumbs.nullOrEmpty(VehicleSearchPerformed.manufacturer)) {
            return crumbs;
        }

        const search = new VehicleSearch(rootSearchUrl);
        search.vehicleType = (VehicleSearchPerformed.vehicleType || '').toLowerCase();
        search.make = VehicleSearchPerformed.manufacturer;

        var link = getSearchUrl(search, true);

        crumbs.push({ title: VehicleSearchPerformed.manufacturerDisplay, link: link });

        return crumbs;
    }

    private static getModelCrumbs(rootSearchUrl: string, VehicleSearchPerformed: VehicleSearchPerformed, getSearchUrl: (search: VehicleSearch, resetSortAndPage?:boolean) => string): BreadcrumbEntry[] {

        var crumbs = Breadcrumbs.getMakeCrumbs(rootSearchUrl, VehicleSearchPerformed, getSearchUrl);
        if (Breadcrumbs.nullOrEmpty(VehicleSearchPerformed.model)) {
            return crumbs;
        }

        const search = new VehicleSearch(rootSearchUrl);
        search.vehicleType = (VehicleSearchPerformed.vehicleType || '').toLowerCase();
        search.make = VehicleSearchPerformed.manufacturer;
        search.model = VehicleSearchPerformed.model;
        var link = getSearchUrl(search, true);

        crumbs.push({ title: VehicleSearchPerformed.modelDisplay, link: link });

        return crumbs;
    }

    private static getBodyTypeCrumbs(rootSearchUrl: string, VehicleSearchPerformed: VehicleSearchPerformed, getSearchUrl: (search: VehicleSearch, resetSortAndPage?:boolean) => string): BreadcrumbEntry[] {

        var crumbs = Breadcrumbs.getvehicleTypeCrumbs(rootSearchUrl, VehicleSearchPerformed, getSearchUrl);
        if (Breadcrumbs.nullOrEmpty(VehicleSearchPerformed.bodyType)) {
            return crumbs;
        }

        const search = new VehicleSearch(rootSearchUrl);
        search.vehicleType = (VehicleSearchPerformed.vehicleType || '').toLowerCase();
        search.bodyType = VehicleSearchPerformed.bodyType;
        var link = getSearchUrl(search, true);

        crumbs.push({ title: VehicleSearchPerformed.bodyTypeDisplay, link: link });

        return crumbs;
    }

    private static getFuelTypeCrumbs(rootSearchUrl: string, VehicleSearchPerformed: VehicleSearchPerformed, getSearchUrl: (search: VehicleSearch, resetSortAndPage?:boolean) => string): BreadcrumbEntry[] {

        var crumbs = Breadcrumbs.getvehicleTypeCrumbs(rootSearchUrl, VehicleSearchPerformed, getSearchUrl);
        if (Breadcrumbs.nullOrEmpty(VehicleSearchPerformed.fuelType)) {
            return crumbs;
        }

        const search = new VehicleSearch(rootSearchUrl);
        search.vehicleType = (VehicleSearchPerformed.vehicleType || '').toLowerCase();
        search.fuelType = VehicleSearchPerformed.fuelType;
        var link = getSearchUrl(search, true);

        crumbs.push({ title: VehicleSearchPerformed.fuelTypeDisplay, link: link });

        return crumbs;
    }

    private static getGearboxTypeCrumbs(rootSearchUrl: string, VehicleSearchPerformed: VehicleSearchPerformed, getSearchUrl: (search: VehicleSearch, resetSortAndPage?:boolean) => string): BreadcrumbEntry[] {

        var crumbs = Breadcrumbs.getvehicleTypeCrumbs(rootSearchUrl, VehicleSearchPerformed, getSearchUrl);
        if (Breadcrumbs.nullOrEmpty(VehicleSearchPerformed.gearbox)) {
            return crumbs;
        }

        const search = new VehicleSearch(rootSearchUrl);
        search.vehicleType = (VehicleSearchPerformed.vehicleType || '').toLowerCase();
        search.gearboxType = VehicleSearchPerformed.gearbox;
        var link = getSearchUrl(search, true);
            
        crumbs.push({ title: VehicleSearchPerformed.gearboxDisplay, link: link });

        return crumbs;
    }

    private static getColourCrumbs(rootSearchUrl: string, VehicleSearchPerformed: VehicleSearchPerformed, getSearchUrl: (search: VehicleSearch, resetSortAndPage?:boolean) => string): BreadcrumbEntry[] {

        var crumbs = Breadcrumbs.getvehicleTypeCrumbs(rootSearchUrl, VehicleSearchPerformed, getSearchUrl);
        if (Breadcrumbs.nullOrEmpty(VehicleSearchPerformed.basicColour)) {
            return crumbs;
        }

        const search = new VehicleSearch(rootSearchUrl);
        search.vehicleType = (VehicleSearchPerformed.vehicleType || '').toLowerCase();
        search.colour = VehicleSearchPerformed.basicColour;
        var link = getSearchUrl(search, true);

        crumbs.push({ title: VehicleSearchPerformed.basicColourDisplay, link: link });

        return crumbs;
    }

    private static buildListItems(crumbs: BreadcrumbEntry[]): HTMLLIElement[] {
        return crumbs.map((crumb, idx) => {
            return Breadcrumbs.buildListItem(crumb.title, crumb.link);
        });
    }

    private static buildListItem(title: string, href: string, isActive?: boolean) {
        var li = document.createElement('li');

        if (href != null) {
            var a = document.createElement('a');
            a.href = href;
            a.textContent = title;
            li.appendChild(a);
        } else {
            li.textContent = title;
        }

        return li;
    }

    private static vehicleToVehicleSearchPerformed(vehicle: Vehicle): VehicleSearchPerformed {
        return new VehicleSearchPerformed(
            vehicle.type,
            vehicle.vehicle_type + 's',
            vehicle.vehicle_type_display + 's',
            vehicle.fuel_type,
            vehicle.fuel_type_display,
            vehicle.manufacturer,
            vehicle.manufacturer_display,
            vehicle.model,
            vehicle.model_display,
            vehicle.body_type,
            vehicle.body_name,
            vehicle.transmission,
            vehicle.transmission_display,
            vehicle.basic_colour,
            vehicle.basic_colour_display
        );
    }

    private static vehicleToSoldVehicleSearchPerformed(vehicle: Vehicle): VehicleSearchPerformed {
        return new VehicleSearchPerformed(
            vehicle.type,
            vehicle.vehicle_type + 's',
            vehicle.vehicle_type_display + 's'
        );
    }
}
