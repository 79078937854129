import { HugoModuleVehicleBranchDetailsConfig } from "themes/module_vehicle_branch_details/static/js/hugoModuleVehicleBranchDetailsConfig";

export class ModuleVehicleBranchDetailsConfig {
    public static FromHugoConfig(
        hugoModuleVehicleBranchDetailsConfig: HugoModuleVehicleBranchDetailsConfig
    ): ModuleVehicleBranchDetailsConfig {
        if (hugoModuleVehicleBranchDetailsConfig == null) {
            return null;
        }

        return new ModuleVehicleBranchDetailsConfig();
    }
}