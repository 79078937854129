import { HugoPageConfig } from "static/js/app/hugoSettings/hugoPageConfig";
import { VehicleStatus, VehicleStatusFromString } from "static/js/app/models/__index";
import { ModuleSimilarVehiclesConfig } from "themes/module_similar_vehicles_slider/static/js/similarVehiclesConfig";

export class PageConfig {
    constructor(
        public vehicleType?: string | null,
        public jsPageId?: string | null,
        public branch_id?: number | null,
        public title?: string,
        public form?: string,
        public url?: string,
        public vehicleStatus?: VehicleStatus | null,
        public number_of_extra_images?: number | null,
        public default_listings_style?: string | null 
    ) {}

    public static FromHugoPageConfig(hugoPageConfig: HugoPageConfig): PageConfig {
        if(hugoPageConfig == null) {
            // use defaults
            return new PageConfig();
        }

        return new PageConfig(
            hugoPageConfig.vehicle_type,
            hugoPageConfig.load_js_page,
            hugoPageConfig.branch_id,
            hugoPageConfig.title,
            hugoPageConfig.form,
            hugoPageConfig.url,
            (hugoPageConfig.vehicle_status !=null ? VehicleStatusFromString(hugoPageConfig.vehicle_status) : null),
            hugoPageConfig.number_of_extra_images,
            hugoPageConfig.default_listings_style

        );
    }
}
