import { SearchTerm, SortTerm, Availability, FinanceSearchTerms,VehicleStatus } from "static/js/app/models/__index";


export class SearchCriteria {
    constructor(
        public searchTerms: SearchTerm[],
        public keyword?: string,
        public sortTerms?: SortTerm[],       
        public pageNumber: number = 1,
        public pageSize: number = 10,
        public availability: Availability = "available",
        public vehicleStatus: VehicleStatus = "used",
        public financeTerms?: FinanceSearchTerms,
        public fetchUrlList: boolean = false
    ) { 
        if(searchTerms != null) {
            const keywordsFilter = searchTerms.filter(t=>t.fieldName == "keywords");

            // api has keywords as a separate filter to search terms
            if(keywordsFilter.length > 0) {
                this.keyword = keywordsFilter[0].targetValue?.toString();
            }
        }
    }
}