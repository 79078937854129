import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import * as Api from "static/js/app/api/endpoints";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import * as Models from "static/js/app/models/__index";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";
import ListingsHelpers from "static/js/app/templateHelpers/ListingsHelpers";

export default class BranchGallery {
    private static sortTerms: { fieldName: string, isDescending: boolean }[];

    private static pageNumber: number;
    private static itemsPerPage: number;
    private static sortBy: string;
    private static order: string;
    private static financeSearchTerms: Models.FinanceSearchTerms;

    public static async init(siteConfig: SiteConfig, pageConfig: PageConfig, onTemplatesLoaded: () => void = null, availability: Models.Availability = "available", vehicleStatus: Models.VehicleStatus = "all") {
        if(pageConfig.branch_id == null) {
            return;
        }

        
        BranchGallery.pageNumber = (parseInt(Page.queryString["page"]) || 1);
        BranchGallery.itemsPerPage = (parseInt(Page.queryString["items-per-page"]) || siteConfig.searchConfig.items_per_page);
        let sortOrder = ListingsHelpers.getSortOrder(siteConfig.searchConfig.sort_by, siteConfig.searchConfig.sort_order);        
        BranchGallery.sortBy = sortOrder.sortBy;
        BranchGallery.order = sortOrder.sortOrder;
        BranchGallery.sortTerms = ListingsHelpers.getSortTerms(BranchGallery.sortBy, BranchGallery.order);
        BranchGallery.financeSearchTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
        const searchTerms = [ new Models.SearchTerm("branch_id", pageConfig.branch_id) ];

        let searchCriteria = new Models.SearchCriteria(
            searchTerms, null, BranchGallery.sortTerms,
            BranchGallery.pageNumber, BranchGallery.itemsPerPage, availability, vehicleStatus,  BranchGallery.financeSearchTerms);

        const searchResults = await Api.Vehicles.search(searchCriteria);

        Page.ready(() => {
            ListingsHelpers.synchronizeSearchInputs();
            VehicleFinanceQuotes.setFinanceSearchTerms(BranchGallery.financeSearchTerms);
            ListingsHelpers.setSortByOptions(BranchGallery.sortBy, BranchGallery.itemsPerPage, BranchGallery.order);
            ListingsHelpers.configureSearchFilterEvents();
            Breadcrumbs.setBreadcrumbs(Breadcrumbs.getGalleryBreadcrumbs(document.location.pathname, pageConfig.title));

            ListingsHelpers.generateSearchResultsHtml(searchResults, pageConfig, siteConfig, "/used/", siteConfig.financeConfig,siteConfig.dealerBranches );

            if (onTemplatesLoaded != null) {
                onTemplatesLoaded();
            }

            ListingsHelpers.initializeDefaultPager(
                searchResults.totalPages,
                BranchGallery.pageNumber,
                (Page.queryString["items-per-page"] != null ? parseInt(Page.queryString["items-per-page"]): null),
                `${window.location.protocol}//${window.location.host}`
            );
        });
    }

}
