import * as Api from "static/js/app/api/endpoints";
import * as Models from "static/js/app/models/__index";

export default class PopularMakes {
	public static generatePopularMakesListItems(selector: string, vehicleType: string, getSearchUrl: (search: Models.VehicleSearch) => string, maxItems: number = 9999) {
        const popularMakesElements = document.querySelectorAll(selector);

        if(popularMakesElements == null || popularMakesElements.length == 0) {
            return;
        }
        
        Api.Vehicles.getMakes({ vehicleType: vehicleType, availability: "available", vehicleStatus: "used"}).then(function (vehicleMakesResults: Models.VehicleCount[]) {
            const searchUrlPrefix = "/used/";
            vehicleMakesResults = vehicleMakesResults
                .sort(PopularMakes.CompareVehicleCount);

            // get top N [maxItems] main veh type makes by count
            let manufacturerLinks:ManufacturerLink[] = [];
            for (var i = 0; i < vehicleMakesResults.length && manufacturerLinks.length < maxItems; i++) {
                var search = new Models.VehicleSearch(searchUrlPrefix);
                search.make = vehicleMakesResults[i].makeValue;
                search.vehicleType = vehicleType + "s" || null;
                
                var searchUrl = getSearchUrl(search);
                manufacturerLinks.push(new ManufacturerLink(vehicleMakesResults[i].makeName, searchUrl));
            }

            // reorganise by make name
            manufacturerLinks = manufacturerLinks.sort(PopularMakes.CompareManufacturerLinkByTitle)

            if(vehicleMakesResults.length > 0) {
                popularMakesElements.forEach(popularMakesEl => {
                    const listItems = document.createDocumentFragment();
            
                    manufacturerLinks.forEach(
                        (manufacturerLink) => {
                            let anchor = document.createElement("a");
                            anchor.href = manufacturerLink.link;
                            anchor.text = manufacturerLink.title;

                            let li = document.createElement("li");
                            li.appendChild(anchor);
                            listItems.appendChild(li);
                        }
                    );
                    
                    const targetHtmlListEl = popularMakesEl.querySelector(".vehicle-makes");
                    targetHtmlListEl.appendChild(listItems)
                    popularMakesEl.classList.remove("hide");
                })
                
            }
        });
    }

    private static CompareVehicleCount(a: Models.VehicleCount, b: Models.VehicleCount) {
        if (a.count < b.count) return 1;
        if (a.count > b.count) return -1;
       return 0;
    }
    private static CompareManufacturerLinkByTitle(a: ManufacturerLink, b: ManufacturerLink) {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
       return 0;
    }
    
}

class ManufacturerLink {
    constructor(
        public title: string,
        public link: string
    ) {}
}
