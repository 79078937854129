import { FinanceQuote } from "static/js/app/models/__index";

function buildVehicleFinanceQuote(fq: FinanceQuote): string {
    return `<tr class ="fmgauto_codeweavers fmgauto-finance-plugins--finance-results__table--${fq.type}">
     <td>${fq.type}</td>
     <td>£${ Number(fq.total_deposit).toLocaleString(undefined, { maximumFractionDigits: 2,minimumFractionDigits: 2 })}</td>
     <td>£${ Number(fq.amount_of_credit).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
     <td>£${ Number(fq.total_amount_of_charges).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
     <td>£${ Number(fq.total_amount_payable).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
     <td>${fq.term}</td>
     <td>${ Number(fq.fixed_rate_of_interest).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2})}%</td>
     <td>${ Number(fq.apr).toLocaleString(undefined, { maximumFractionDigits: 2})}%</td>
     <td>£${ Number(fq.monthly_payment).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
     <td>£${ Number(fq.monthly_payment).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} x ${fq.contract_length}</td>
     <td>£${ Number(fq.final_payment).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
     </tr>`;
}


export function buildVehicleFinanceQuotes(fq: FinanceQuote[]): string { 
    if (fq == null || fq.length == 0)   
    {
        return '';
    }
    return `<table class="l-node-used-vehicle--search-result__finance-results_table">
    <thead>
        <tr><th>&nbsp;</th>
            <th>Deposit</th>
            <th>Balance</th>
            <th class="fmgauto-finance-plugins--finance-results__table--help" title="* Fees are already accounted for within the payments displayed and are also included within the total amount payable.">Total Charge For Credit*</th>
            <th>Total Payable inc Deposit</th>
            <th>Term</th>
            <th>Fixed Rate (Per Annum)</th>
            <th>APR</th>
            <th>1st Pmt</th>
            <th>Monthly Pmt</th>
            <th>Final Pmt</th>
        </tr>
    </thead>
        <tbody>
            ${fq.map(f=> buildVehicleFinanceQuote(f)).join("")}
        </tbody>
    </table>`;
}

