export class FinanceSearchTerms {
    constructor(
         public term: number,
         public annual_mileage: number,
         public deposit_amount?: number,
         public percentage_deposit? : number,        
         public minimum_monthly_payment?: number,
         public maximum_monthly_payment?: number,
         public isDefaultTerms?: boolean
    ){}
}
