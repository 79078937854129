export class VehicleCount {
    constructor(
        public count: number,
        public typeName: string,
        public typeValue: string,
        public makeName?: string,
        public makeValue?: string,
        public modelName?: string,
        public modelValue?: string
    ) { }
}