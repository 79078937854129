export class VehicleImageUrls {
    [key: string]: string;
    constructor(
        public full: string,
        public i800x600: string,
        public i320x240: string,
        public i160x120: string,
        public i850x638: string,
        public i1000x750: string,
        public i1920x1440: string,
    ) {}
}