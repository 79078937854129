import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import "magnific-popup";
import { StickyElement } from "static/js/app/modules/stickyElement";
import VehicleSingleTemplate from "static/js/app/modules/vehicleSingleTemplate";
import * as Api from "static/js/app/api/endpoints";
import VehiclePageMeta from "static/js/app/modules/vehiclePageMeta";
import VehicleGallery from "static/js/app/modules/vehicleGallery";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";

import { HugoVehicleDetailsPageConfig } from "static/js/app/hugoSettings/hugoSiteConfig";
import * as Models from "static/js/app/models/__index";
import { VehiclePageConfig } from "static/js/app/hugoSettings/pageConfigs/vehiclePageConfig";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import GoogleAnalytics from "themes/module_google_analytics/static/js/googleAnalytics";
import VehicleRunningCostsCalculator from "themes/module_running_costs_calculator/static/js/vehicleRunningCostsCalculator";
import VehicleBranchDetails from "themes/module_vehicle_branch_details/static/js/vehicleBranchDetails";
import { finance_plugin } from "themes/module_finance_plugin/static/js/finance_plugin";
import { PartExchange } from "themes/module_part_exchange/static/js/partExchange";
import { ValuationPartExchange } from "themes/module_vehicle_valuation/static/js/vehicleValuation";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";
import SimilarVehicles from "themes/module_similar_vehicles_slider/static/js/similarVehicles";
import { ModuleSimilarVehiclesConfig } from "themes/module_similar_vehicles_slider/static/js/similarVehiclesConfig";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";


export default class VehiclePage {
	public static init(siteConfig: SiteConfig, pageConfig: VehiclePageConfig, onTemplatesLoaded: () => void) {
		var vehicleId = Page.queryString['id'];
		const financeTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
		
		Page.ready(function () {
				
			const templateTargetEl = document.getElementById("vehicleDetailsResult");

			VehicleSingleTemplate.init( templateTargetEl);
			if (siteConfig.searchConfig.enable_previous_next_buttons)
			{
				VehiclePage.initNextPrevious();
			}
			onTemplatesLoaded();

			if(pageConfig.showRunningCostCalculator) {
				console.log("init RCC");
				VehicleRunningCostsCalculator.init( document.getElementById('running-costs-calculator'));
			}

			VehicleGallery.init();

			if(siteConfig.moduleVehicleBranchDetails != null) {
				VehicleBranchDetails.init();
			}

			if (siteConfig.financeConfig)
			{					
				finance_plugin.init(siteConfig.financeConfig, financeTerms,pageConfig.vehicle);			
			}

			if(siteConfig.vehicleDetailsPageSettings.enableStickyEnquire) {
				var stickyElement = $('.ga-vehicle-enquiry');
				var stickyWatchElement = $('.vehicle-ctas');

				new StickyElement(stickyElement, stickyWatchElement);
			}

			//init part exchange tool
			const partExchange = new PartExchange("#part-exchange-form", "#part-exchange-cta", pageConfig.form, siteConfig.service_quotation_tool);
			const valuationpartExchange = new ValuationPartExchange("#vehicle-valuation-form", "#vehicle-valuation-result-form", '#valuation-cta', pageConfig.form, siteConfig.service_quotation_tool);
		});
		

		if(siteConfig.similarVehiclesConfig != null) {
			this.initSimilarVehicles(siteConfig.similarVehiclesConfig, parseInt(vehicleId), financeTerms);
		}
	}

	static initNextPrevious()
	{
		let urlList = sessionStorage.getItem("searchResultPaths");
		const prevButtonDiv = document.getElementById('vehicle-previous-placeholder');

	    const nextButtonDiv = document.getElementById('vehicle-next-placeholder');
		if (urlList ==null || urlList.length <= 0)
		{
			if (prevButtonDiv != null){
				prevButtonDiv.parentElement.removeChild(prevButtonDiv);
			}
			if (nextButtonDiv !=null) {
				nextButtonDiv.parentElement.removeChild(nextButtonDiv);
			}
			return;
		}
			var urls = JSON.parse(urlList) as string[];						
			if (urls.length > 0 && prevButtonDiv != null && nextButtonDiv !=null)
			{
				let currentIndex = urls.findIndex(ul=> ul ==window.location.pathname);
				if (currentIndex <= 0 || urls[currentIndex-1] == null)
				{
					prevButtonDiv.parentElement.removeChild(prevButtonDiv);
				}
				else{
					const prevButton = document.createElement("a") as HTMLAnchorElement;
					prevButton.id = "vehicle-previous";					
					prevButton.classList.add("button");									
					prevButton.href = urls[currentIndex-1];
					prevButton.text = "Previous Ad";
					prevButtonDiv.appendChild(prevButton);
				}
				if (currentIndex>= urls.length-1 || urls[currentIndex+1] == null)
				{					
					nextButtonDiv.parentElement.removeChild(nextButtonDiv);
				}
				else{
					const nextButton = document.createElement("a") as HTMLAnchorElement;
					nextButton.id = "vehicle-next";	
					nextButton.href = urls[currentIndex+1];					
					nextButton.classList.add("button");
					nextButton.text = "Next Ad";
					nextButtonDiv.appendChild(nextButton);
				}
			}
	}
	
	static initSimilarVehicles(similarVehiclesConfig: ModuleSimilarVehiclesConfig, vehicleId: number, financeTerms?: Models.FinanceSearchTerms) {	
		console.log("similar vehicles initialized");
		Api.Vehicles.getSimilarVehicles(vehicleId,financeTerms).then(function (vehicles: Models.Vehicle[]) {
			SimilarVehicles.init(similarVehiclesConfig, vehicles);
		});
	}
}

export class VehicleDetailsPageConfig {
    constructor(
        public enableStickyEnquire: boolean = false,
        public saveButtonBasicStyleClass: string = "compare-button",
        public galleryImageSize: string  = "i800x600",
		public galleryThumbSize: string  = "i160x120",
		public maxDealerPhoneNumbers: number = 999
    ) {}

    public static FromHugoVehicleDetailsPageConfig(vehicleDetailsPageConfig: HugoVehicleDetailsPageConfig): VehicleDetailsPageConfig {
        if(vehicleDetailsPageConfig == null) {
            // use defaults
            return new VehicleDetailsPageConfig();
        }

        return new VehicleDetailsPageConfig(
            vehicleDetailsPageConfig.enable_sticky_enquire,
            vehicleDetailsPageConfig.save_button_basic_style_class,
            vehicleDetailsPageConfig.gallery_image_size,
			vehicleDetailsPageConfig.gallery_thumb_size,
			vehicleDetailsPageConfig.max_dealer_phone_numbers
        );
    }
}
