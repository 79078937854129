export class HugoModuleLatestVehiclesSliderConfig {
    public main: HugoModuleLatestVehiclesSliderConfigMode
    public home: HugoModuleLatestVehiclesSliderConfigMode
    // Additional dynamic HugoModuleLatestVehiclesSliderConfigMode fields can be added (TS won't know about them but they will be there)
    // If you know the settings will be used across multiple templates, it would be better to add the setting here and add it to the "config.toml" file in this module but don't worry too much - you can always add it later
}

export class HugoModuleLatestVehiclesSliderConfigMode {
    public filters: string[] = []
    public sort_by: string[] = ["featured", "latest"]
    public dom_element_id: string
    public dom_template_element_id: string 
    public show_section_header: boolean = true
    public image_header_html_template: string = "%vehicle_make_display% %vehicle_model_display%"
    public show_price: boolean = true
    public show_monthly_payment: boolean = false
    public image_size: string = "i800x600"
    public number_of_items_to_display: number = 8
    public slick_settings: string = '"{' +
        '"slidesToShow": 1,' + 
        '"slidesToScroll": 1,' + 
        '"arrows": true,' + 
        '"lazyLoad": "ondemand",' + 
        '"prevArrow": "<span class=\'slick-prev main\'>&lt;</span>",' + 
        '"nextArrow": "<span class=\'slick-next main\'>&gt;</span>",' + 
        '"autoplay": true,' + 
        '"autoplaySpeed": 3000,' + 
        '"mobileFirst": true,' + 
        '"responsive": [' + 
        '{' + 
        '    "breakpoint": 767,' + 
        '    "settings": {' + 
        '        "slidesToShow": 2,' + 
        '        "autoplay": true' + 
        '    }' + 
        '},' + 
        '{' + 
        '    "breakpoint": 1023,' + 
        '    "settings": {' + 
        '        "slidesToShow": 4,' + 
        '        "autoplay": true' + 
        '    }' + 
        '}]' + 
    '}"'
    public include_on_homepage: boolean
    public equal_height: boolean

    public static applyDefaultsForUndefined(cfg: HugoModuleLatestVehiclesSliderConfigMode, showMonthlyPrice: boolean = false): HugoModuleLatestVehiclesSliderConfigMode {
        var cfgMode = new HugoModuleLatestVehiclesSliderConfigMode();

        cfgMode.dom_element_id = cfg.dom_element_id;
        cfgMode.dom_template_element_id = cfg.dom_template_element_id;
        cfgMode.include_on_homepage = cfg.include_on_homepage ?? false;
        cfgMode.equal_height = cfg.equal_height ?? false;
        cfgMode.show_monthly_payment = showMonthlyPrice; // fudgy - this is set in core params so templates can override easily (hugo not good at merging configs currently!!!)

        cfgMode.image_header_html_template = cfg.image_header_html_template ?? "%vehicle_make_display% %vehicle_model_display%";

        cfgMode.sort_by = cfg.sort_by ?? ["featured", "latest"];

        cfgMode.filters = cfg.filters ?? [];

        cfgMode.image_size = cfg.image_size ?? "i800x600";

        cfgMode.slick_settings = cfg.slick_settings ?? '"{' +
            '"slidesToShow": 1,' + 
            '"slidesToScroll": 1,' + 
            '"arrows": true,' + 
            '"lazyLoad": "ondemand",' + 
            '"prevArrow": "<span class=\'slick-prev main\'>&lt;</span>",' + 
            '"nextArrow": "<span class=\'slick-next main\'>&gt;</span>",' + 
            '"autoplay": true,' + 
            '"autoplaySpeed": 3000,' + 
            '"mobileFirst": true,' + 
            '"responsive": [' + 
            '{' + 
            '    "breakpoint": 767,' + 
            '    "settings": {' + 
            '        "slidesToShow": 2,' + 
            '        "autoplay": true' + 
            '    }' + 
            '},' + 
            '{' + 
            '    "breakpoint": 1023,' + 
            '    "settings": {' + 
            '        "slidesToShow": 4,' + 
            '        "autoplay": true' + 
            '    }' + 
            '}]' + 
        '}"';

        cfgMode.show_section_header = cfg.show_section_header ?? true;

        cfgMode.show_price = cfg.show_price ?? true;

        cfgMode.number_of_items_to_display = cfg.number_of_items_to_display ?? 8;

        return cfgMode;
    }
}