export default class HeaderSearch {
    public static init() {
        var menuOpen = $('#menu-open');
        var menuClose = $('#menu-close');

        if(menuOpen.length && menuClose.length) {
            menuOpen.on("click", function () {
                $(".l-header__nav-inner").addClass("open");
                $("body").addClass("no-overflow");
                $("#menu-open").hide();
            });

            menuClose.on("click", function () {
                $(".l-header__nav-inner").removeClass("open");
                $("body").removeClass("no-overflow");
                $("#menu-open").show();
            });
        }
    }
}
