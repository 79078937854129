import { Availability } from "static/js/app/models/availability"


export class HugoModuleVehiclesSliderConfig {
    public filters: string[] = []
    public sort_by: string[] = ["date_added"]
    public availability: Availability = "available"
    public dom_element_id: string = "recentlyAddedVehiclesHomeSlider"
    public dom_template_element_id: string ="recentlyAddedVehiclesHomeTemplate"
    public show_section_header: boolean = true
    public image_header_html_template: string = "%vehicle_make_display% %vehicle_model_display%"
    public show_price: boolean = true
    public show_monthly_payment: boolean = false
    public image_size: string = "i800x600"
    public number_of_items_to_display: number = 8
    public slick_settings: string
    public equal_height: boolean = false

    constructor(o: HugoModuleVehiclesSliderConfig) {
        if(o == null) { return null }

        Object.assign(this, o);      
    }
}